<template>
  <div class="main-box" style="min-height: 800px; background-color: #f5f5f5">
    <el-form class="form-inline" :inline="true" v-if="storeList && storeList.length>0">
      <el-form-item label="选择门店">
        <el-select v-model="search.store" placeholder="请选择门店" size="small" @change="init()">
          <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
<!--        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>-->
      </el-form-item>
    </el-form>
    <div v-if="isShow">
      <el-tabs type="border-card">
        <el-tab-pane label="今日统计">
          <el-date-picker
              v-model="params.day1"
              type="date"
              size="small"
              @change="getStaticToday()"
              placeholder="请选择日期">
          </el-date-picker>
        </el-tab-pane>
        <div class="table" v-if="data1">
          <div class="item">
            <div class="label">每日运营成本：</div>
            <div class="value">￥{{ data1.const_amount }}</div>
          </div>
          <div class="item">
            <div class="label">当日毛利润：</div>
            <div class="value">￥{{ data1.gross_amount }}</div>
          </div>
          <div class="item">
            <div class="label">当日净利润：</div>
            <div class="value">￥{{ data1.profit_amount }}</div>
          </div>
          <div class="item">
            <div class="label">收款金额：</div>
            <div class="value">￥{{ data1.pay_amount }}</div>
          </div>
        </div>
      </el-tabs>
      <el-tabs type="border-card" class="mg-tp-20" v-if="data2 && data2.length>0">
        <el-tab-pane label="产品统计">
          <el-date-picker
              v-model="params.day2"
              type="daterange"
              size="small"
              @change="getStaticGoods()"
              placeholder="请选择日期">
          </el-date-picker>
          <div class="box" style="padding: 0; margin-top: 20px; width: 100%; overflow-x: auto;">
            <table :width="data2[0].length*120 + 'px'" class="table-box tc">
              <tr v-for="(item,index) in data2" :key="index">
                <td width="100" :class="{bg5: index==0 || cindex==0, bg1: cindex==item.length-1}" v-for="(child,cindex) in item" :key="cindex">{{ child }}</td>
              </tr>
            </table>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-tabs type="border-card" class="mg-tp-20" v-if="data3 && data3.length>0">
        <el-tab-pane label="员工绩效">
          <el-date-picker
              v-model="params.day3"
              type="month"
              size="small"
              @change="getStaticStaff()"
              placeholder="请选择月份">
          </el-date-picker>
          <div class="box" style="padding: 0; margin-top: 20px; width: 100%; overflow-x: auto;">
            <table :width="data3[0].length*120 + 'px'" class="table-box tc">
              <tr v-for="(item,index) in data3" :key="index">
                <td width="100" :class="{bg5: index==0}" v-for="(child,cindex) in item" :key="cindex">{{ child }}</td>
              </tr>
            </table>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-tabs type="border-card" class="mg-tp-20" v-if="data4">
        <el-tab-pane label="利润统计">
          <el-date-picker
              v-model="params.day4"
              type="daterange"
              size="small"
              @change="getStaticProfit()"
              placeholder="请选择日期">
          </el-date-picker>
          <div class="table">
            <div class="item">
              <div class="label">总营业额：</div>
              <div class="value">￥{{ data4.pay_amount }}</div>
            </div>
            <div class="item">
              <div class="label">毛利润：</div>
              <div class="value">￥{{ data4.gross_profit_amount }}</div>
            </div>
            <div class="item">
              <div class="label">净利润：</div>
              <div class="value">￥{{ data4.net_profit_amount }}</div>
            </div>
            <div class="item">
              <div class="label">预期营业额：</div>
              <div class="value">￥{{ data4.expect_amount }}</div>
            </div>
            <div class="item">
              <div class="label">剩余月天数：</div>
              <div class="value">{{ data4.day }} 天</div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>

import util from "@/utils/util";
export default {
  components: {
  },
  data() {
    return {
      info: false,
      isShow: true,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        store: ''
      },
      storeList: [],
      params: {
        day1: '',
        day2: ['',''],
        day3: '',
        day4: [],
      },
      colspan: 20,
      data1: false,
      data2: [
        // ['项目名称','玻璃膜','玻璃膜','玻璃膜','玻璃膜','玻璃膜','玻璃膜','玻璃膜','玻璃膜','玻璃膜','玻璃膜','总业务'],
        // ['数量','4','4','4','4','4','4','4','4','4','4','30'],
        // ['占比','4%','4%','4','4','4','4','4','4','4','4','*'],
        // ['达标进度条','4%','4%','4','4','4','4','4','4','4','4','*'],
        // ['达标数值','4%','4%','4','4','4','4','4','4','4','4','*'],
      ],
      data3: [
        // ['姓名','总收入业绩','总个人收入业绩','总组长收入业绩','今日业绩'],
        // ['吕文轩','4','4','4','4'],
        // ['吕文轩','4','4','4','4'],
        // ['吕文轩','4','4','4','4'],
        // ['吕文轩','4','4','4','4'],
        // ['吕文轩','4','4','4','4'],
      ],
      data4: false
    };
  },
  created() {
    var date = new Date();
    this.params.day1 = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    this.params.day2[0] = date.getFullYear() + '-' + (date.getMonth() + 1) + '-01'
    this.params.day2[1] = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    this.params.day3 = date.getFullYear() + '-' + (date.getMonth() + 1)
    this.params.day4[0] = date.getFullYear() + '-' + (date.getMonth() + 1) + '-01'
    this.params.day4[1] = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()

    this.getMyInfo()
    if(this.$route.query.msg) {
      this.isShow = true
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    init() {
      this.getStaticToday()
      this.getStaticGoods()
      this.getStaticStaff()
      this.getStaticProfit()
    },
    getMyInfo() {
      this.$api.merchant.myIndex(res => {
        if(res.errcode==0) {
          this.info = res.data
          if(res.data.user_type==1) {
            this.getStoreList()
          } else {
            this.init()
            // this.getSInfo()
          }
        }
      })
    },
    getStoreList() {
      this.$api.merchant.storeIndex({page_size: 100}, res => {
        if (res.errcode == 0) {
          this.storeList = res.data.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getStaticToday() {
      this.showLoading()
      var params = {
        date:util.formatDate(new Date(this.params.day1)),
        store:this.search.store
      }
      this.$api.operate.staticToday(params, res => {
        this.hideLoading()
        if(res.errcode==0) {
          this.data1 = res.data
        } else {
          this.fail(res.errmsg);
        }
      })
    },
    getStaticGoods() {
      this.showLoading()
      var params = {
        start_date:util.formatDate(new Date(this.params.day2[0])),
        end_date:util.formatDate(new Date(this.params.day2[1])),
        store:this.search.store
      }
      this.$api.operate.staticGoods(params, res => {
        this.hideLoading()
        if(res.errcode==0) {
          this.data2 = res.data
        } else {
          this.fail(res.errmsg);
        }
      })
    },
    getStaticStaff() {
      this.showLoading()
      var params = {
        date:util.formatDate(new Date(this.params.day3)),
        store:this.search.store
      }
      this.$api.operate.staticStaff(params, res => {
        this.hideLoading()
        if(res.errcode==0) {
          this.data3 = res.data
        } else {
          this.fail(res.errmsg);
        }
      })
    },
    getStaticProfit() {
      this.showLoading()
      var params = {
        start_date:util.formatDate(new Date(this.params.day4[0])),
        end_date:util.formatDate(new Date(this.params.day4[1])),
        store:this.search.store
      }
      this.$api.operate.staticProfit(params, res => {
        this.hideLoading()
        if(res.errcode==0) {
          this.data4 = res.data
        } else {
          this.fail(res.errmsg);
        }
      })
    }
  }
};
</script>
<style lang="less" scoped>
.table {
  padding: 20px 0;
  .item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    .label {
      width: 120px;
      color: #666;
      font-size: 14px;
    }
    .value {
      color: #333;
      font-size: 14px;
    }
  }
}
</style>
